/* eslint-disable @typescript-eslint/naming-convention */
import { Environments } from '@/enums/environments';

// Split out to decrease bundle size of dg-auth
export default {
    1: {
        [Environments.local]: `https://api-t1.stage.dg.toyota.com`,
        [Environments.dev]: `https://api-t1.dev.dg.toyota.com`,
        [Environments.test]: `https://api-t1.test.dg.toyota.com`,
        [Environments.stage]: `https://api-t1.stage.dg.toyota.com`,
        [Environments.demo]: `https://api-t1.demo.dg.toyota.com`,
        [Environments.prod]: `https://api-t1.dg.toyota.com`,
    },
    2: {
        [Environments.local]: `https://api-t1.stage.dg.toyota.com`,
        [Environments.dev]: `https://api-t1.dev.dg.toyota.com`,
        [Environments.test]: `https://api-t1.test.dg.toyota.com`,
        [Environments.stage]: `https://api-t1.stage.dg.toyota.com`,
        [Environments.demo]: `https://api-t1.demo.dg.toyota.com`,
        [Environments.prod]: `https://api-t1.dg.toyota.com`,
    },
    3: {
        [Environments.local]: `https://api.stage.dg.toyota.com`,
        [Environments.dev]: `https://api.dev.dg.toyota.com`,
        [Environments.test]: `https://api.test.dg.toyota.com`,
        [Environments.stage]: `https://api.stage.dg.toyota.com`,
        [Environments.demo]: `https://api.demo.dg.toyota.com`,
        [Environments.prod]: `https://api.dg.toyota.com`,
    },
};
