export enum Routes {
    owners = '/owners',
    dashboard = '/owners/dashboard',
    /** deprecated */
    profile = '/profile',
    saves = '/saves',
    tradeIns = '/trade',
    /** deprecated */
    deals = '/my-deals',
}
