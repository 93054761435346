import { Brands } from '@/enums/brands';

/** It is preferred to use the global variables: __IS_TOYOTA__ or __IS_LEXUS__
 * as these variables are populated by their actual value upon compilation by webpack and allow for
 * smaller package sizes and in some cases better performance
 * */
export const getBrand = (): Brands => {
    return (import.meta.env.VITE_BRAND || Brands.toyota) as Brands;
};

export function asBrand(brand: string | undefined): Brands | undefined {
    switch (brand?.toLowerCase()) {
        case 'toyota':
            return Brands.toyota;
        case 'lexus':
            return Brands.lexus;
        default:
            return undefined;
    }
}
