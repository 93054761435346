// This file is basically the bottom of the import tree, so be careful what you import from here
import { Brands } from '@/enums/brands';
import { DgComponent } from '@/enums/dgComponent';
import { Environments } from '@/enums/environments';
import { Routes } from '@/enums/routes';
import type { LocalFeatureConfig } from '@/services/features.service';
import { asBrand, getBrand } from '@/utilities/brand';
import { getCircularReplacer } from '@/utilities/json';
import type { PageDGDataHub } from './PageDgDataHub.model';

/**
 * Only gives access to the configs that the base site sets up on the DGDataHub
 * These should all be available as soon as our script loads
 */
export abstract class ReadonlyDGDataHub {
    protected static get _windowDataHub(): PageDGDataHub {
        return window.DGDataHub;
    }

    static validatePageDataHub(): void {
        if (!(this._windowDataHub && typeof this._windowDataHub === 'object')) {
            throw new Error('window.DGDataHub must be defined as an object');
        }
        if (typeof this._windowDataHub.DEPLOY_ENV !== 'string') {
            throw new Error('DGDataHub.DEPLOY_ENV must be defined as a string');
        }

        const scriptBrand = getBrand();
        // eslint-disable-next-line @typescript-eslint/naming-convention
        if (ReadonlyDGDataHub.brand !== scriptBrand) {
            // eslint-disable-next-line no-console
            console.warn('DGDataHub.BRAND does not match dg-ui script version', {
                scriptBrand,
                DGDataHubBrand: ReadonlyDGDataHub.brand,
            });
        }

        if (__DEV__) {
            try {
                // eslint-disable-next-line no-console
                console.debug(
                    'DGDataHub is valid, continue dg-ui load',
                    JSON.stringify(
                        {
                            ...this._windowDataHub,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __internal__: undefined,
                        },
                        getCircularReplacer(),
                        2
                    )
                );
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn(error);
            }
        }
    }

    static isComponentIncludedOnPage(componentName: DgComponent): boolean {
        if (componentName === DgComponent.inlineSaveHeart) {
            // inline save heart is included by default
            return true;
        }
        return !!this._windowDataHub.components?.[componentName];
    }

    static get brand(): Brands {
        return asBrand(this._windowDataHub.BRAND) || Brands.toyota;
    }

    static get environment(): Environments {
        const dataHubEnv = this._windowDataHub?.DEPLOY_ENV.toLowerCase() as Environments;
        if (dataHubEnv) {
            return dataHubEnv;
        }
        if (window.location.toString().includes('staging.buyatoyota.com')) {
            return Environments.demo;
        }
        return Environments.local;
    }

    static get isMstc(): boolean {
        return !!this._windowDataHub.isMSTC;
    }

    static get dealerCd(): string | undefined {
        return this._windowDataHub.dealerCd;
    }

    static get zipCode(): string | undefined {
        return this._windowDataHub.zipCode;
    }

    static get showContinuePurchaseText(): boolean {
        return this._windowDataHub.showContinuePurchaseText ?? true;
    }

    /** @deprecated use the builds url from DIS instead */
    static get buildsRoute(): string | null {
        return this._windowDataHub.pageRoutes?.buildRoute || null;
    }
    static get savesRoute(): string {
        return this._windowDataHub.pageRoutes?.savesRoute || Routes.saves;
    }
    /** @deprecated This was the old version when deals/purchases were on a separate route from saves */
    static get dealsRoute(): string {
        return this._windowDataHub.pageRoutes?.dealsRoute || Routes.deals;
    }
    /** @deprecated This was the old version when we owned our own account management page */
    static get profileRoute(): string {
        return Routes.profile;
    }

    static get dealerAgnosticFeatureConfig(): LocalFeatureConfig | undefined {
        return this._windowDataHub.features?.dealerAgnostic;
    }

    static get invertNavMenuIcons(): boolean {
        return !!this._windowDataHub.invertNavMenuIcons;
    }

    static get isInIFrame(): boolean {
        return !!this._windowDataHub.iFrame;
    }
}
