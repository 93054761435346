export enum DgComponent {
    topNavSaveHeart = 'dg-save-heart',
    inlineSaveHeart = 'dg-inline-saves',
    shoppingCart = 'dg-shopping-cart',
    navMenu = 'dg-nav-menu',
    /** @deprecated */
    progressTracker = 'dg-progress-tracker',
    /** @deprecated */
    toastNotification = 'dg-toast-notification',
}
