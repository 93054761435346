import constants from '@/constants';

export type Tier = 1 | 2 | 3;
export function getTier(): Tier {
    const hostname = window.location.hostname;
    if (
        hostname.includes(constants.tierPatterns.t2ToyotaPattern) ||
        hostname.includes(constants.tierPatterns.t2ToyotaSubprodPattern)
    ) {
        return 2;
    } else if (
        hostname.includes(constants.tierPatterns.t1ToyotaPattern) ||
        (hostname.includes(constants.tierPatterns.t1LexusPattern) &&
            !hostname.includes(constants.MstcSubdomain['lexus']))
    ) {
        return 1;
    } else {
        return 3;
    }
}
