export function isSamePath(pathA: string, pathB: string): boolean {
    return normalizePath(pathA) === normalizePath(pathB);
}

function normalizePath(path: string) {
    return path.split('/').filter(Boolean).join('/');
}

export function getBaseDomain(url: Location): string {
    const locationHostSplit = url.hostname.split('.');
    if (locationHostSplit.length > 2) {
        return `.${locationHostSplit[locationHostSplit.length - 2]}.${locationHostSplit[locationHostSplit.length - 1]}`;
    } else {
        return url.hostname;
    }
}

export function getPageOrigin(host = window?.location?.href): string | null {
    try {
        const hostname = new URL(host).hostname;
        const hostPieces = hostname.split('.');
        if (hostPieces.length > 1) {
            return hostPieces.slice(hostPieces.length - 2).join('.');
        }
        if (hostPieces[0]) {
            return hostPieces[0];
        }
        return __IS_LEXUS__ ? 'lexus.com' : 'tldealersystems.com';
    } catch {
        return null;
    }
}

export function isCurrentPageVdp(): boolean {
    return !(
        window.location.href.indexOf('.toyota.com/dealerinventory/search') > -1 ||
        window.location.href.indexOf('/inventory/search') > -1
    );
}

// TODO: use new URL ?
export function removeBaseUrl(url: string): string {
    const baseUrlPattern = /^https?:\/\/[a-z:0-9.]+/;

    const match = baseUrlPattern.exec(url);
    const result = match?.[0];

    if (result && result.length > 0) {
        return url.replace(result, '');
    }

    return url;
}

export function asValidWebUrl(candidate: string | undefined): URL | null {
    if (!candidate) {
        return null;
    }
    let url;
    try {
        url = new URL(candidate);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
        return null;
    }
    if (url.protocol === 'http:' || url.protocol === 'https:') {
        return url;
    }
    return null;
}
