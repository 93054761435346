import { AuthEvent, EventType, makeAuthEvent } from './events';
import { waitForWindowEventHub, WindowEventHub } from './windowEventHub';

export async function createEventHub(): Promise<EventHub> {
    const windowEventHub = await waitForWindowEventHub();
    const eventHub = new EventHub(windowEventHub);
    return eventHub;
}

class EventHub {
    private readonly windowEventHub;

    constructor(windowEventHub: WindowEventHub) {
        this.windowEventHub = windowEventHub;
    }

    getEvents(): AuthEvent[] {
        const eventHistory = this.windowEventHub.events;
        if (!Array.isArray(eventHistory)) {
            throw new Error('Event history must be an array');
        }

        const authEvents = eventHistory.map((rawEvent) => makeAuthEvent(rawEvent));

        // TODO: remove once PKCE updates to clear old events on new login/logout
        const eventsFromLatestAction = authEvents.reduce<AuthEvent[]>((pastEvents, currentEvent) => {
            // When starting a new login/logout, ignore all past events
            const safePastEvents =
                currentEvent.type === EventType.tokenRequested || currentEvent.type === EventType.tokenDeleted
                    ? []
                    : pastEvents;
            safePastEvents.push(currentEvent);
            return safePastEvents;
        }, []);
        return eventsFromLatestAction;
    }
}

export type { EventHub };
