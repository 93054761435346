import AuthLogger from '@/auth-script/authLogger';
import Cookies from 'js-cookie';
import { getBaseDomain } from '@/utilities/url';

export default class PersistedArray<T> {
    private readonly key;
    private readonly isEqual;
    private readonly sizeLimit;

    constructor(key: string, sizeLimit: number, isEqual?: (a: T, b: T) => boolean) {
        this.key = key;
        this.isEqual = isEqual;
        this.sizeLimit = sizeLimit;
    }

    addItem(item: T): boolean {
        const currentItems = this.items;
        if (currentItems.some((i) => this.isSameItem(i, item))) {
            AuthLogger.debug('Skipping add because it is already in the list', item);
            return false;
        }
        this.items = [...currentItems, item];
        return true;
    }

    get items(): T[] {
        try {
            const existingValue = Cookies.get(this.key);
            const existingItems = existingValue ? JSON.parse(existingValue) : [];
            return Array.isArray(existingItems) ? existingItems : [];
        } catch {
            return [];
        }
    }
    private set items(newItems: T[]) {
        const trimmedItems = newItems.slice(-this.sizeLimit); // slice the list, so the newest items are preserved
        const newValue = JSON.stringify(trimmedItems);
        Cookies.withConverter({
            write: (value) => {
                return value.toString();
            },
        }).set(this.key, newValue, {
            domain: getBaseDomain(window.location),
        });
    }

    removeItem(itemToRemove: T): void {
        this.items = this.items.filter((item) => !this.isSameItem(item, itemToRemove));
    }

    private isSameItem(a: T, b: T) {
        if (typeof this.isEqual === 'function') {
            return this.isEqual(a, b);
        }
        return a === b;
    }
}
