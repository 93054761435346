import { retryUntilTruthyOrTimeout } from '@/utilities/promise';
import { RawAuthEvent } from './events';

export async function waitForWindowEventHub(): Promise<WindowEventHub> {
    const windowEventHub = await retryUntilTruthyOrTimeout(getWindowEventHub, {
        delayMs: 50,
        timeoutMs: 10000,
    });
    return validateWindowEventHub(windowEventHub);
}

function getWindowEventHub(): unknown {
    return window.pkce?.eventHistory;
}

function validateWindowEventHub(potentialEventHub: unknown): WindowEventHub {
    if (!potentialEventHub) {
        throw new Error('Event Hub was not truthy');
    }
    if (!Array.isArray((potentialEventHub as WindowEventHub).events)) {
        throw new Error(`Event Hub did not have 'events' property that is an array`);
    }
    return potentialEventHub as WindowEventHub;
}

export interface WindowEventHub {
    events: unknown | RawAuthEvent[];
}
