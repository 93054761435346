import AuthState from './authState';
import EventHandler from './eventHandler';
import { createEventHub, EventHub } from './eventHub';
import AuthLogger from './authLogger';

async function main(): Promise<void> {
    try {
        const authState = new AuthState();

        const eventHub = await getEventHub();
        if (!eventHub) {
            return;
        }

        AuthLogger.debug('Handling events');
        await handleEvents(eventHub, authState);
        AuthLogger.debug('Finished handling events');
    } catch (error) {
        AuthLogger.error(error);
    }
}

async function getEventHub() {
    try {
        return await createEventHub();
    } catch (error) {
        AuthLogger.warn('Unable to create Event Hub', error);
        return null;
    }
}

async function handleEvents(eventHub: EventHub, authState: AuthState) {
    const eventHandler = new EventHandler(authState);

    // on toyota.com/federate-identity, events may be added after page load, once the token is retrieved
    // we want to capture and handle these events
    // PKCE saves these on local storage, so they are not available across domain, like on smartpath.toyota.com
    // we queue them in a .toyota.com cookie, so that we can access them
    window.addEventListener('beforeunload', () => {
        const events = eventHub.getEvents();
        AuthLogger.debug('Queueing events', JSON.stringify(events)); // stringify bc console cannot expand objects after a page navigation
        eventHandler.queueEvents(events);
    });

    await eventHandler.finishPendingEvents();

    const events = eventHub.getEvents();
    for (const event of events) {
        await eventHandler.handleEvent(event);
    }
}

export default main;
