import { Environments } from '@/enums/environments';

export type AuthEvent = TokenRequestedEvent | TokenCreatedEvent | TokenDeletedEvent;

export enum EventType {
    tokenRequested = 'TOKEN_REQUESTED',
    tokenCreated = 'TOKEN_CREATED',
    tokenDeleted = 'TOKEN_DELETED',
}

type DgEvent<Type extends EventType, Data extends Record<string, unknown> = Record<string, never>> = {
    type: Type;
    data: {
        env: Environments;
        requestId: number;
    } & Data;
};
type TokenRequestedEvent = DgEvent<EventType.tokenRequested>;
type TokenCreatedEvent = DgEvent<EventType.tokenCreated>;
type TokenDeletedEvent = DgEvent<
    EventType.tokenDeleted,
    {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        logout_reason: 'MANUAL_LOGOUT' | 'SESSION_TIMEOUT';
    }
>;

export function makeAuthEvent(event: unknown): AuthEvent {
    if (!validateRawAuthEvent(event)) {
        throw new Error('Invalid event');
    }
    const type = Object.keys(event)[0] as keyof RawAuthEvent;
    const data = event[type];
    return {
        type,
        data,
    } as AuthEvent;
}

export function isSameAuthEvent(a: AuthEvent, b: AuthEvent): boolean {
    return (
        a.type === b.type && !!a.data.requestId && a.data.requestId === b.data.requestId && a.data.env === b.data.env
    );
}

function validateRawAuthEvent(event: unknown): event is RawAuthEvent {
    if (!event || typeof event !== 'object') {
        throw new RawAuthEventValidateError('Must be a truthy object', event);
    }

    const keys = Object.keys(event || {});
    if (keys.length !== 1) {
        throw new RawAuthEventValidateError('Must have only one key', event);
    }

    const key = keys[0];
    if (!isValidRawAuthKey(key)) {
        throw new RawAuthEventValidateError(`Key must be an event type, not '${key}'`, event);
    }

    const data = (event as RawAuthEvent)[key];
    if (!data?.env) {
        throw new RawAuthEventValidateError(`Data must contain an 'env' property`, event);
    }
    if (!data?.requestId) {
        throw new RawAuthEventValidateError(`Data must contain a 'requestId' property`, event);
    }

    return true;
}

class RawAuthEventValidateError extends Error {
    event;
    constructor(reason: string, event: unknown) {
        super(`Invalid RawAuthEvent: ${reason}`);
        this.event = event;
    }
}

function isValidRawAuthKey(key: string): key is keyof RawAuthEvent {
    const validTypes = Object.values(EventType) as string[];
    return validTypes.includes(key);
}

export type RawAuthEvent = Partial<{
    [key in EventType]: {
        env: Environments;
        requestId: number;
    };
}>;
