import { Brands } from '@/enums/brands';

export default {
    MstcSubdomain: {
        [Brands.toyota]: 'smartpath',
        [Brands.lexus]: 'monogram',
    },
    defaultBuildsRoute: {
        [Brands.toyota]: '/configurator',
        [Brands.lexus]: '/build-your-lexus/#!/series',
    } as Record<Brands, string>,
    tierPatterns: {
        t1ToyotaPattern: '.toyota.com',
        t2ToyotaPattern: '.buyatoyota.com',
        t2ToyotaSubprodPattern: '.nonprod.jsds.tms.aws.toyota.com',
        t1LexusPattern: '.lexus.com',
    },
};
