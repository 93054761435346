/* eslint-disable no-console */
import { Environments } from '@/enums/environments';

/**
 * Logger class abstracts away console logging and allows environment-specific logging levels as well as
 * identification of what modules logs are made in, regardless of script minification.
 */
export default class BaseLogger {
    readonly moduleName: string;
    private minLogLevel = 0;
    private context;

    constructor(prefix: string, env?: Environments, moduleName?: string, context?: Record<string, unknown>) {
        const suffix = moduleName ? `::${moduleName}` : '';
        this.moduleName = `${prefix}${suffix}`;
        this.context = context;
        if (env) {
            this.setEnv(env);
        }
    }

    setEnv(env: Environments): void {
        if (__DEV__) {
            this.minLogLevel = 0;
            return;
        }

        if (env === 'prod') {
            this.minLogLevel = 2;
        } else if (env === 'demo') {
            this.minLogLevel = 1;
        } else {
            this.minLogLevel = 0;
        }
    }

    debug(...args: unknown[]): void {
        if (this.minLogLevel <= 0) {
            console.debug(`${this.moduleName}: `, ...args, this.context);
        }
    }

    info(...args: unknown[]): void {
        if (this.minLogLevel <= 1) {
            console.info(`${this.moduleName}: `, ...args, this.context);
        }
    }

    log(...args: unknown[]): void {
        if (this.minLogLevel <= 1) {
            console.log(`${this.moduleName}: `, ...args, this.context);
        }
    }

    warn(...args: unknown[]): void {
        if (this.minLogLevel <= 2) {
            console.warn(`${this.moduleName}: `, ...args, this.context);
        }
    }

    error(...args: unknown[]): void {
        if (this.minLogLevel <= 3) {
            console.error(`${this.moduleName}: `, ...args, this.context);
        }
    }
}
