type SubscribeOptions = Partial<{
    immediate: boolean;
}>;
type Subscriber<T> = (value: T) => symbol | Promise<symbol> | unknown;
type Unsubscribe = () => void;
export interface IReadonlyObservable<T> {
    getValue(): T;
    subscribe(subscriber: Subscriber<T>, options?: SubscribeOptions): Unsubscribe;
}
export interface Observable<T> extends IReadonlyObservable<T> {
    setValue(newValue: T): void;
}

export class DgObservable<T> implements Observable<T> {
    static unsubscribeToken = Symbol('unsubscribe');

    #value: T;
    readonly #subscribed = new Set<Subscriber<T>>();

    #listenedTo = false;
    readonly #onFirstListen?: () => void;

    constructor(initialValue: T, onFirstListen?: () => void) {
        this.#value = initialValue;
        this.#onFirstListen = onFirstListen;
    }

    #onListen(): void {
        if (this.#listenedTo) {
            return;
        }
        this.#listenedTo = true;
        this.#onFirstListen?.();
    }

    getValue(): T {
        this.#onListen();
        return this.#value;
    }
    setValue(newValue: T): void {
        if (this.#value === newValue) {
            return;
        }
        this.#value = newValue;
        this.#subscribed.forEach((subscriber) => this.#callSubscriber(subscriber));
    }
    subscribe(subscriber: Subscriber<T>, { immediate }: SubscribeOptions = {}): Unsubscribe {
        this.#onListen();
        this.#subscribed.add(subscriber);
        if (immediate) {
            this.#callSubscriber(subscriber);
        }
        return () => this.#unsubscribe(subscriber);
    }

    async #callSubscriber(subscriber: Subscriber<T>): Promise<void> {
        const response = await Promise.resolve(subscriber(this.#value));
        if (response === DgObservable.unsubscribeToken) {
            this.#unsubscribe(subscriber);
        }
    }
    #unsubscribe(subscriber: Subscriber<T>): void {
        this.#subscribed.delete(subscriber);
    }
}

export class ReadonlyDgObservable<T> implements IReadonlyObservable<T> {
    #obs!: DgObservable<T>;

    constructor(updater: (setValue: (newValue: T) => void) => void, onFirstListen?: () => void) {
        updater((newValue: T) => {
            this.#obs ??= new DgObservable(newValue, onFirstListen);
            this.#obs.setValue(newValue);
        });
        if (!this.#obs) {
            throw new Error('updater must call setValue immediately');
        }
    }

    getValue(): T {
        return this.#obs.getValue();
    }
    subscribe(subscriber: Subscriber<T>, options?: SubscribeOptions): Unsubscribe {
        return this.#obs.subscribe(subscriber, options);
    }
}

export function getFirstTruthyValue<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve) => {
        observable.subscribe(
            (newValue) => {
                if (newValue) {
                    resolve(newValue);
                    return DgObservable.unsubscribeToken;
                }
                return undefined;
            },
            { immediate: true }
        );
    });
}

export function waitForObservableToEqual<T>(observable: IReadonlyObservable<T>, value: T): Promise<void> {
    if (observable.getValue() === value) {
        return Promise.resolve();
    }
    return new Promise((resolve) => {
        const unsubscribe = observable.subscribe((newValue) => {
            if (newValue === value) {
                resolve();
                unsubscribe();
            }
        });
    });
}
