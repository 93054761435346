import BaseLogger from '@/models/baseLogger.model';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const idTokenLogger = new BaseLogger('getIdToken');

export function isJWT(potentialJWT: string | null): boolean {
    if (potentialJWT) {
        try {
            return Object.keys(jwtDecode(potentialJWT)).length > 0;
        } catch {
            idTokenLogger.debug(`Invalid JWT provided`);
            return false;
        }
    } else {
        return false;
    }
}

export function getIdToken(idTokenName: string): string | null {
    const idTokenCookieResult = checkIdTokenCookie(idTokenName);
    if (idTokenCookieResult.isValidJWT) {
        return idTokenCookieResult.jwt;
    } else if (idTokenCookieResult.pkceSaysTheyAreLoggedIn) {
        idTokenLogger.debug(`Getting id_token information from local storage`);
        const localIdToken = localStorage.getItem(idTokenName);
        return localIdToken || null;
    }
    return null;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function checkIdTokenCookie(idTokenName: string) {
    const idTokenCookie = Cookies.get(idTokenName);
    if (typeof idTokenCookie === 'string') {
        if (isJWT(idTokenCookie)) {
            return {
                isValidJWT: true,
                jwt: idTokenCookie,
            };
        } else if (idTokenCookie.toLowerCase() === 'true') {
            return {
                pkceSaysTheyAreLoggedIn: true,
            };
        }
    }
    return {};
}
