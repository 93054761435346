import Cookies from 'js-cookie';
import { getPageOrigin } from './url';

export class BooleanCookie {
    private readonly name;
    constructor(name: string) {
        this.name = name;
    }

    get(): boolean {
        return getCookie(this.name) === `${true}`;
    }
    set(value: boolean): void {
        if (value) {
            setCookie(this.name, `${value}`);
        } else {
            removeCookie(this.name);
        }
    }
}

function getCookie(cookieName: string) {
    return Cookies.get(cookieName);
}
function setCookie(cookieName: string, value: string) {
    Cookies.set(cookieName, value, getCookieOptions());
}
function removeCookie(cookieName: string) {
    Cookies.remove(cookieName, getCookieOptions());
}

function getCookieOptions() {
    const domain = getPageOrigin();
    const options = { domain: domain || undefined };
    return options;
}
