import { Environments } from '@/enums/environments';

export function getDgidCookieName(env: Environments): string {
    return `dgid_${env}`;
}

export function getLogoutDataCookieName(env: Environments): string {
    return `dg_logout_data_${env}`;
}

export function getWasAutoLoggedOutCookieName(): string {
    return `dg_was_auto_logged`;
}

export function getShowAutoLoggedOutNotificationCookieName(): string {
    return `dg_show_auto_logged_out_notification`;
}

export function getAccessTokenCookieName(env: Environments): string {
    let azurePostFix = '';
    switch (env) {
        case Environments.dev:
            azurePostFix = '_rtb2cdev';
            break;
        case Environments.test:
            azurePostFix = '_rtb2ctest';
            break;
        case Environments.stage:
        case Environments.demo:
            azurePostFix = '_tmnab2cqa';
            break;
    }
    return `id_token${azurePostFix}`;
}
