export function wait(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

type CancelToken = {
    isCanceled: boolean;
};
export function cancelAfter<T>(ms: number, promiseFactory: (token: CancelToken) => Promise<T>): Promise<T> {
    const token: CancelToken = {
        isCanceled: false,
    };
    const timeout = setTimeout(() => (token.isCanceled = true), ms);
    return promiseFactory(token).finally(() => clearTimeout(timeout));
}

type Options = {
    delayMs: number;
    timeoutMs: number;
    suppressErrors: boolean;
};
const defaultOptions: Options = {
    delayMs: 100,
    timeoutMs: 1000,
    suppressErrors: false,
};
export function retryUntilTruthyOrTimeout<T>(getter: () => T, options?: Partial<Options>): Promise<T> {
    const _options = { ...defaultOptions, ...options };
    return cancelAfter(_options.timeoutMs, async (cancelToken) => {
        while (!cancelToken.isCanceled) {
            try {
                const result = getter();
                if (result) {
                    return result;
                }
            } catch (error) {
                if (!_options.suppressErrors) {
                    throw error;
                }
            }
            await wait(_options.delayMs);
        }
        return getter();
    });
}

export function allConditional<T>(promises: (Promise<T> | false)[]): Promise<(T | null)[]> {
    return Promise.all(promises.map((p) => p || Promise.resolve(null)));
}

export function isSuccessful<T>(response: PromiseSettledResult<T>): response is PromiseFulfilledResult<T> {
    return 'value' in response;
}

export async function timeoutAfter<T, R = undefined>(
    promise: Promise<T>,
    ms: number,
    timeoutResponse?: R
): Promise<T | R | undefined> {
    return Promise.any([promise, wait(ms).then(() => timeoutResponse)]);
}
